import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Castiglioni Advogados </title>
                    <meta name="description" content="A Castiglioni Advogados é uma assessoria especializada em causas cíveis e empresariais que atua com exclusividade para a Way Back, além de mais de 255 escritórios parceiros espalhados por todo o território nacional.
" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
